import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService';

function FinancesTagged() {
  const [prices, setPrices] = useState([]);
  const [refiData, setRefiData] = useState([]);

  useEffect(() => {
    getPrices();
    getRefiData();
  }, []);

  const getPrices = async () => {
    try {
      const token = await authService.getAccessToken();
      var response = await fetch('api/finances/getprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      var data = await response.json();
      setPrices(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRefiData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/GetRefiData', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setRefiData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePricesClick = async () => {
    console.log("handleUpdatePricesClick");
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/updateassetprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });

      await getPrices();
    } catch (error) {
      console.error(error);
    }
  };

  const ETFInfo = 
  {
    BtcPerShare: 0.00088939,
    EthPerShare: 0.00945914,
  }

  const settlementTotals =
  {
    irafSOL: 228.2683273,
    irafSOLCost: -14093.04, //-14065,
    irafETH: 4.097411,
    irafETHCost: -13388.38, //-13379.67,
    geminiQuantity: 824.9509157,
    geminiCost: -87058,
  }

  const toCurrency = (str) =>
  {
    return (str ? str.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "");
  }

  const toPercent = (str) => {
    return (str ? str.toLocaleString('en-US', { style: 'percent'}) : "");
  }

  const buttonStyle = {
    padding: "2px",
    fontSize: "20px",
    marginLeft: "10px",
    border: 'none',
    backgroundColor: 'white',
  };

  const irafSettlementCurrent = settlementTotals.irafSOL * prices["SOL"]?.price + settlementTotals.irafETH * prices["ETH"]?.price;
  const geminiSettlementCurrent = settlementTotals.geminiQuantity * prices["SOL"]?.price;
  const settlementCurrent = irafSettlementCurrent + geminiSettlementCurrent;
  const irafCost = settlementTotals.irafETHCost + settlementTotals.irafSOLCost;
  const remainingOwed = prices["ETH"]?.price * 76 - settlementCurrent * .725;
  const remainingOwedAsEth = remainingOwed / prices["ETH"]?.price;
  const refiCostBasis = refiData["USD"];
  const refiCurrentTotal = Object.keys(refiData).reduce((acc, symbol) => {
    if (symbol === "USD") return acc;
    return acc + prices[symbol]?.price * refiData[symbol];
  }, 0);
  const refiFutureTotal = Object.keys(refiData).reduce((acc, symbol) => {
    if (symbol === "USD") return acc;
    return acc + prices[symbol]?.futurePrice * refiData[symbol];
  }, 0);

  return (
    <div>
      <div>
        <h2>Refinance</h2>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <td>Symbol</td>
              <td>Quantity</td>
              <td>Current Value</td>
              <td>Future Value</td>
            </tr>
          </thead>
          <tbody>
            {
              Object.entries(refiData).map(([symbol, value]) => (
                <tr>
                  <td>{symbol}</td>
                  <td>{value}</td>
                  <td>{toCurrency(prices[symbol]?.price * value)}</td>
                  <td>{toCurrency(prices[symbol]?.futurePrice * value)}</td>
                </tr>
              ))
            }
            <tr>
              <td>Total</td>
              <td></td>
              <td><b>{toCurrency(refiCurrentTotal)}</b></td>
              <td><b>{toCurrency(refiFutureTotal)}</b></td>
            </tr>
            <tr>
              <td>Gains</td>
              <td></td>
              <td><b>{toCurrency(refiCurrentTotal - refiCostBasis)} ({toPercent((refiCurrentTotal - refiCostBasis) / refiCostBasis)})</b></td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <h2>Tagged Transactions <button style={buttonStyle} onClick={handleUpdatePricesClick}>(&#10227;)</button></h2>
        <br></br>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <td>Tag</td>
                <td>Current Value</td>
                <td>Cost</td>
                <td>Gains</td>
                <td>Change</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="5"></td>
              </tr>
              <tr>
              <td>Settlement (with fee)</td>
              <td>{toCurrency(settlementCurrent)}</td>
              <td>{toCurrency(irafCost + settlementTotals.geminiCost)}</td>
              <td>{toCurrency(settlementCurrent + irafCost + settlementTotals.geminiCost)}</td>
              <td>{Math.abs(settlementCurrent / (settlementTotals.geminiCost + irafCost) * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>Settlement (after fee)</td>
                <td>{toCurrency(settlementCurrent * 0.725)}</td>
                <td>{toCurrency((irafCost + settlementTotals.geminiCost)*0.725)}</td>
                <td>{toCurrency((settlementCurrent + irafCost + settlementTotals.geminiCost)*0.725)}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="5"></td>
              </tr>
              <tr>
                <td>Gemini (with fee)</td>
                <td>{toCurrency(geminiSettlementCurrent)}</td>
                <td>{toCurrency(settlementTotals.geminiCost)}</td>
                <td>{toCurrency(geminiSettlementCurrent + settlementTotals.geminiCost)}</td>
                <td>{Math.abs(geminiSettlementCurrent / settlementTotals.geminiCost * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>Gemini (after fee)</td>
                <td>{toCurrency(geminiSettlementCurrent * 0.725)}</td>
                <td>{toCurrency(settlementTotals.geminiCost * 0.725)}</td>
                <td>{toCurrency((geminiSettlementCurrent + settlementTotals.geminiCost) * 0.725)}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="5"></td>
              </tr>
              <tr>
                <td>IRAF (with fee)</td>
                <td>{toCurrency(irafSettlementCurrent)}</td>
                <td>{toCurrency(irafCost)}</td>
                <td>{toCurrency(irafSettlementCurrent + irafCost)}</td>
                <td>{Math.abs(irafSettlementCurrent / irafCost * 100).toFixed(2)} %</td>
              </tr>
              <tr>
                <td>IRAF (after fee)</td>
                <td>{toCurrency(irafSettlementCurrent * 0.725)}</td>
                <td>{toCurrency(irafCost * 0.725)}</td>
                <td>{toCurrency((irafSettlementCurrent + irafCost) * 0.725)}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="5"></td>
              </tr>
              <tr>
                <td>76 ETH (stolen amount)</td>
                <td>{toCurrency(prices["ETH"]?.price * 76)}</td>
                <td colspan="2">Diff {toCurrency(remainingOwed)} (after fee)</td>
              <td>(Net by coins: {(76 - remainingOwedAsEth).toFixed(2)} ETH, {(((76 - remainingOwedAsEth) / 76) * 100).toFixed(4)}%) Remaining: {remainingOwedAsEth.toFixed(2)} ETH {toCurrency(prices["ETH"]?.price * remainingOwedAsEth)}</td>
              </tr>
              <tr>
                <td>45.8243595 ETH (vs SOL)</td>
                <td>{toCurrency(prices["ETH"]?.price * 45.8243595)}</td>
                <td colspan="2">Diff {toCurrency((settlementTotals.irafSOL + settlementTotals.geminiQuantity) * prices["SOL"]?.price - prices["ETH"]?.price * 45.8243595)}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="5"></td>
              </tr>
              <tr>
                <td>GBTC</td>
                <td>{toCurrency(prices["GBTC"]?.price)}</td>
                <td>{toCurrency(prices["BTC"]?.price * ETFInfo.BtcPerShare)}</td>
                <td></td>
                <td>
                  {(1 - (prices["GBTC"]?.price) / (prices["BTC"]?.price * ETFInfo.BtcPerShare))
                    .toLocaleString("en-US", { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td>ETHE</td>
                <td>{toCurrency(prices["ETHE"]?.price)}</td>
                <td>{toCurrency(prices["ETH"]?.price * ETFInfo.EthPerShare)}</td>
                <td></td>
                <td>
                  {(1 - (prices["ETHE"]?.price) / (prices["ETH"]?.price * ETFInfo.EthPerShare))
                    .toLocaleString("en-US", { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  );
}

export default FinancesTagged;