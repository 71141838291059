import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService';
import Table, { CurrencyCell, MultiSelectFilter, includesOr } from './controls/Table';

function FinancesWithdrawals() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getwithdrawals', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

/*
  const transactionColumns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Footer: '',
      },
      {
        Header: 'Account',
        accessor: 'account.friendlyName',
        Filter: MultiSelectFilter,
        filter: includesOr,
        Footer: '',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Footer: '',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: MultiSelectFilter,
        filter: includesOr,
        Footer: '',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
        Footer: '',
      },
      {
        Header: 'Current Quantity',
        accessor: 'currentQuantity',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentQuantity + sum, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Original Quantity',
        accessor: 'originalQuantity',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.originalQuantity + sum, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const price = Math.abs(row.original.currentBasis / row.original.currentQuantity);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = Math.abs(rowA.original.currentBasis / rowA.original.currentQuantity);
          const b = Math.abs(rowB.original.currentBasis / rowB.original.currentQuantity);
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: '',
      },
      {
        Header: 'CostBasis',
        accessor: 'currentBasis',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.currentBasis;
          const b = rowB.original.currentBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'OriginalBasis',
        accessor: 'originalBasis',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.originalBasis;
          const b = rowB.original.originalBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.originalBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'USDChange',
        accessor: 'usdChange',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.usdChange;
          const b = rowB.original.usdChange;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.usdChange + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'CryptoFee',
        accessor: 'cryptoFee',
        sortType: (rowA, rowB) => {
          const a = rowA.original.cryptoFee;
          const b = rowB.original.cryptoFee;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.cryptoFee + sum, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'FeesUSD',
        accessor: 'feesUSD',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.feesUSD;
          const b = rowB.original.feesUSD;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.feesUSD + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
    ],
    []
  );
  */

  const withdrawalLotColumns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Footer: '',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
        Footer: '',
      },
      {
        Header: 'Shares',
        accessor: 'shares',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.shares + sum, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'DatePurchased',
        accessor: 'datePurchased',
        Footer: '',
      },
      {
        Header: 'CostBasis',
        accessor: 'costBasis',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.costBasis + sum, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const price = Math.abs(row.original.costBasis / row.original.shares);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        Footer: '',
      },
      {
        Header: 'Transaction.Type',
        accessor: 'originTransaction.type',
        Footer: '',
      },
      {
        Header: 'Transaction.OriginalQuantity',
        accessor: 'originTransaction.originalQuantity',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.originTransaction?.originalQuantity || 0);
          }, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Transaction.CurrentQuantity',
        accessor: 'originTransaction.currentQuantity',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.originTransaction?.currentQuantity || 0);
          }, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Transaction.OriginalBasis',
        accessor: 'originTransaction.originalBasis',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.originTransaction?.originalBasis || 0);
          }, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Transaction.CurrentBasis',
        accessor: 'originTransaction.currentBasis',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.originTransaction?.currentBasis || 0);
          }, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Transaction.FeesUSD',
        accessor: 'originTransaction.feesUSD',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.originTransaction?.feesUSD || 0);
          }, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Transaction.CryptoFee',
        accessor: 'originTransaction.cryptoFee',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => {
            return sum + (row.original.originTransaction?.cryptoFee || 0);
          }, 0);
          return <>{total}</>;
        },
      },
    ],
    []
  );

  return (
    <div>
      <div>
        <h2>Withdrawals</h2>
        <div>
          {data?.map(withdrawalDetails => {
            return (
              <div>
                <h5>Withdrawal:
                  {withdrawalDetails.withdrawalTransaction.symbol} |
                  date: {withdrawalDetails.withdrawalTransaction.date } |
                  #: ({withdrawalDetails.withdrawalTransaction.originalQuantity}) ->
                  ({withdrawalDetails.withdrawalTransaction.currentQuantity}) |
                  Cost: ({withdrawalDetails.withdrawalTransaction.originalBasis}) ->
                  ({withdrawalDetails.withdrawalTransaction.currentBasis}) | 
                  Fee: $({withdrawalDetails.withdrawalTransaction.feesUSD})
                  ({withdrawalDetails.withdrawalTransaction.cryptoFee})
                </h5>
                <h5>Deposit:
                  {withdrawalDetails.matchingDeposit?.depositTransaction.symbol} |
                  date: {withdrawalDetails.matchingDeposit?.depositTransaction.date} |
                  #: ({withdrawalDetails.matchingDeposit?.depositTransaction.originalQuantity}) ->
                  ({withdrawalDetails.matchingDeposit?.depositTransaction.currentQuantity})|
                  Cost: ({withdrawalDetails.matchingDeposit?.depositTransaction.originalBasis}) ->
                  ({withdrawalDetails.matchingDeposit?.depositTransaction.currentBasis}) |
                  Fee: $({withdrawalDetails.matchingDeposit?.depositTransaction.feesUSD})
                  ({withdrawalDetails.matchingDeposit?.depositTransaction.cryptoFee})
                </h5>
                <h5>
                  AssetDeposit:
                  Cost: ({withdrawalDetails.matchingDeposit?.costBasis}) |
                  Fee: ({withdrawalDetails.matchingDeposit?.withdrawalFee})
                </h5>
                <h5>Diff:
                  #: {withdrawalDetails.withdrawalTransaction.originalQuantity + withdrawalDetails.matchingDeposit?.depositTransaction.originalQuantity}
                </h5>
                <Table
                  columns={withdrawalLotColumns}
                  data={withdrawalDetails.withdrawalLots}>
                </Table>
              </div>
            );
          })

            /*
            
                <Table
                  columns={transactionColumns}
                  data={[
                    withdrawalDetails.withdrawalTransaction,
                    withdrawalDetails.matchingDeposit?.depositTransaction,
                    ...withdrawalDetails.originTransactions,
                  ].filter(Boolean)}>
                </Table>
            
            data?.map(withdrawalDetails => {
            return (
                <div>
                  <h4>Withdrawal Transaction:</h4>
                  {withdrawalDetails.withdrawalTransaction ? (
                    <Table columns={transactionColumns} data={[withdrawalDetails.withdrawalTransaction]} />
                  ) : (
                    <p>No transaction data available</p>
                  )}
                  <h4>Deposit Transaction:</h4>
                  {withdrawalDetails.matchingDeposit ? (
                    <Table columns={transactionColumns} data={[withdrawalDetails.matchingDeposit.originTransaction]} />
                  ) : (
                    <p>No transaction data available</p>
                  )}
                  <h4>Origin Transactions:</h4>
                  <Table
                    columns={transactionColumns}
                    data={withdrawalDetails.originTransactions}>
                  </Table>
                </div>
              );
            })*/}
        </div>
      </div>
    </div>
  );
}

export default FinancesWithdrawals;