import React, { useEffect, useState } from 'react';
import authService from './api-authorization/AuthorizeService';
import Table, { CurrencyCell, MultiSelectFilter, includesOr } from './controls/Table';
//import TransactionModal from './controls/TransactionModal';

function FinancesSummary() {
  const [data, setData] = useState([]);
  const [prices, setPrices] = useState([]);
  const [futureSymbol, setFutureSymbol] = useState('');
  const [futurePrice, setFuturePrice] = useState('');
  //const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getPrices();
    getData();
  }, []);

  //const handleShow = () => setShowModal(true);
  //const handleClose = () => setShowModal(false);

  const getData = async () => {
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/getsummary', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPrices = async () => {
    try {
      const token = await authService.getAccessToken();
      var response = await fetch('api/finances/getprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });
      var data = await response.json();
      setPrices(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdatePricesClick = async () => {
    console.log("handleUpdatePricesClick");
    try {
      const token = await authService.getAccessToken();
      const response = await fetch('api/finances/updateassetprices', {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
      });

      await getPrices();
    } catch (error) {
      console.error(error);
    }
  };

  const handleFutureUpdate = () => {
    prices[futureSymbol].futurePrice = parseFloat(futurePrice);
    setPrices({ ...prices });
    setFuturePrice('');
  };

  const topTotals = {
    currentTotal: 0,
    futureTotal: 0,
    totalGains: 0,
    taxFreeGains: 0,
    shortTermGains: 0,
    longTermGains: 0,
  }

  const mortgageInfo = {
    outstandingBalance: 726628.79,
    balanceDate: "11/09/2024",
    houseValue: 1400000,
  };

  const isLongTerm = (date) => {
    const nowUtc = new Date();
    nowUtc.setUTCFullYear(nowUtc.getUTCFullYear() - 1);
    return nowUtc >= new Date(date);
  };

  const transactions = data?.find(taxGroup => taxGroup.name === "All")?.symbolGroups?.filter(symbolGroup => symbolGroup.costBasis < -0.01)
    .flatMap(symbolGroup => symbolGroup.transactions);
  transactions?.reduce((acc, transaction) => {
      if (prices[transaction.symbol]) {
        var currentValue = prices[transaction.symbol].price * transaction.currentQuantity;
        var gains = currentValue + transaction.currentBasis;

        topTotals.totalGains += gains;
        if (transaction.account.taxFree) {
          topTotals.taxFreeGains += gains;
        }
        else if (isLongTerm(transaction.date)) {
          topTotals.longTermGains += gains;
        }
        else {
          topTotals.shortTermGains += gains;
        }

        topTotals.currentTotal += currentValue;
        topTotals.futureTotal += (prices[transaction.symbol].futurePrice * transaction.currentQuantity);
      }
      else {
        console.error(`Price not found for symbol: ${transaction.symbol}`);
      }
      return acc;
    }, topTotals);

  const toCurrency = (str) => {
    return (str != null ? str.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "");
  }

  const bySymbolColumns = React.useMemo(
    () => [
      {
        Header: 'Symbol',
        accessor: 'symbol',
        Filter: MultiSelectFilter,
        filter: includesOr,
        Footer: '',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ value }) => { return value.toFixed(8); },
        Footer: '',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const price = Math.abs(row.original.costBasis / row.original.quantity);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = Math.abs(rowA.original.costBasis / rowA.original.quantity);
          const b = Math.abs(rowB.original.costBasis / rowB.original.quantity);
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: '',
      },
      {
        Header: 'CostBasis',
        accessor: 'costBasis',
        Cell: CurrencyCell, //({ value }) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value); },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.costBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'CurrentPrice',
        accessor: 'currentPrice',
        Cell: ({ row }) => {
          const currentPrice = prices[row.original.symbol]?.price;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentPrice)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = prices[rowA.original.symbol]?.price;
          const b = prices[rowB.original.symbol]?.price;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: '',
      },
      {
        Header: 'CurrentTotal',
        accessor: 'currentTotal',
        Cell: ({ row }) => {
          const currentTotal = prices[row.original.symbol]?.price * row.original.quantity;
          return <><b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentTotal)}</b></>;
        },
        sortType: ( rowA, rowB ) => {
          const a = prices[rowA.original.symbol]?.price * rowA.original.quantity;
          const b = prices[rowB.original.symbol]?.price * rowB.original.quantity;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.quantity * prices[row.values.symbol]?.price + sum, 0);
          return <><b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</b></>;
        },
      },
      {
        Header: 'Unrealized Gains',
        accessor: 'unrealizedGains',
        Cell: ({ row }) => {
          const gains = prices[row.original.symbol]?.price * row.original.quantity + row.original.costBasis;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(gains)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = prices[rowA.original.symbol]?.price * rowA.original.quantity + rowA.original.costBasis;
          const b = prices[rowB.original.symbol]?.price * rowB.original.quantity + rowB.original.costBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.costBasis + row.values.quantity * prices[row.values.symbol]?.price + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Realized Gains',
        accessor: 'realizedGains',
        Cell: ({ value }) => { return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)}</>; },
        sortType: (rowA, rowB) => {
          const a = rowA.original.realizedGains;
          const b = rowB.original.realizedGains;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.realizedGains + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Total Gains',
        accessor: 'gains',
        Cell: ({ row }) => {
          const gains = prices[row.original.symbol]?.price * row.original.quantity + row.original.costBasis + row.original.realizedGains;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(gains)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = prices[rowA.original.symbol]?.price * rowA.original.quantity + rowA.original.costBasis + rowA.original.realizedGains;
          const b = prices[rowB.original.symbol]?.price * rowB.original.quantity + rowB.original.costBasis + rowB.original.realizedGains;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.costBasis + row.values.quantity * prices[row.values.symbol]?.price + row.values.realizedGains + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'FuturePrice',
        accessor: 'futurePrice',
        Cell: ({ row }) => {
          const futurePrice = prices[row.original.symbol]?.futurePrice;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(futurePrice)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = prices[rowA.original.symbol]?.futurePrice;
          const b = prices[rowB.original.symbol]?.futurePrice;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: '',
      },
      {
        Header: 'FutureTotal',
        accessor: 'futureTotal',
        Cell: ({ row }) => {
          const futureTotal = prices[row.original.symbol]?.futurePrice * row.original.quantity;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(futureTotal)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = prices[rowA.original.symbol]?.futurePrice * rowA.original.quantity;
          const b = prices[rowB.original.symbol]?.futurePrice * rowB.original.quantity;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.quantity * prices[row.values.symbol]?.futurePrice + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
    ],
    [prices]
  );

  const transactionColumns = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Footer: '',
      },
      {
        Header: 'Account',
        accessor: 'account.friendlyName',
        Filter: MultiSelectFilter,
        filter: includesOr,
        Footer: '',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Footer: '',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: MultiSelectFilter,
        filter: includesOr,
        Footer: '',
      },
      {
        Header: 'Quantity',
        accessor: 'currentQuantity',
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentQuantity + sum, 0);
          return <>{total}</>;
        },
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const price = Math.abs(row.original.currentBasis / row.original.currentQuantity);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = Math.abs(rowA.original.currentBasis / rowA.original.currentQuantity);
          const b = Math.abs(rowB.original.currentBasis / rowB.original.currentQuantity);
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: '',
      },
      {
        Header: 'CostBasis',
        accessor: 'currentBasis',
        Cell: CurrencyCell,
        sortType: (rowA, rowB) => {
          const a = rowA.original.currentBasis;
          const b = rowB.original.currentBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentBasis + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'CurrentTotal',
        accessor: 'currentTotal',
        Cell: ({ row }) => {
          const currentTotal = prices[row.original.symbol]?.price * row.original.currentQuantity;
          return <><b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(currentTotal)}</b></>;
        },
        sortType: (rowA, rowB) => {
          const a = prices[rowA.original.symbol]?.price * rowA.original.currentQuantity;
          const b = prices[rowB.original.symbol]?.price * rowB.original.currentQuantity;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentQuantity * prices[row.original.symbol]?.price + sum, 0);
          return <><b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</b></>;
        },
      },
      {
        Header: 'Gains',
        accessor: 'gains',
        Cell: ({ row }) => {
          const gains = prices[row.original.symbol]?.price * row.original.currentQuantity + row.original.currentBasis;
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(gains)}</>;
        },
        sortType: (rowA, rowB) => {
          const a = prices[rowA.original.symbol]?.price * rowA.original.currentQuantity + rowA.original.currentBasis;
          const b = prices[rowB.original.symbol]?.price * rowB.original.currentQuantity + rowB.original.currentBasis;
          return a === b ? 0 : a > b ? 1 : -1;
        },
        Footer: info => {
          const total = info.rows.reduce((sum, row) => row.values.currentBasis + row.values.currentQuantity * prices[row.original.symbol]?.price + sum, 0);
          return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total)}</>;
        },
      },
      {
        Header: 'Tax',
        accessor: 'tax',
        Cell: ({ row }) => {
          return row.original.account.taxFree ? "-" : isLongTerm(row.original.date) ? "Long" : "Short";
        },
      },
    ],
    [prices]
  );

  const getNestedData = (row) => {
    return row.original.transactions || [];
  };

  const buttonStyle = {
    padding: "2px",
    fontSize: "20px",
    marginLeft: "10px",
    border: 'none',
    backgroundColor: 'white',
  };

  /*
        <TransactionModal
          show={showModal}
          onHide={handleClose}
          transactions={transactions}
        />
        
      <div className="container mt-4">
        <a href="#" onClick={(e) => { e.preventDefault(); handleShow(); }}>
          View Transactions
        </a>
      </div>
  */

  return (
    <div>
      <div>
        <h2>Summary <button style={buttonStyle} onClick={handleUpdatePricesClick}>(&#10227;)</button></h2>
        <div>
          <br></br>
          <table style={{ display: 'inline-block', margin: 10, verticalAlign: 'top' }}>
            <thead>
              <tr>
                <th></th>
                <th>Current</th>
                <th>Future</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Investments</td>
                <td>{toCurrency(topTotals.currentTotal)}</td>
                <td>{toCurrency(topTotals.futureTotal)}</td>
              </tr>
              <tr>
                <td style={{ paddingRight: 30 }}>Home Equity</td>
                <td style={{ paddingRight: 40 }}>{toCurrency(mortgageInfo.houseValue - mortgageInfo.outstandingBalance)}</td>
                <td style={{ paddingRight: 100 }}>{toCurrency(mortgageInfo.houseValue)}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td><b>{toCurrency(topTotals.currentTotal + mortgageInfo.houseValue - mortgageInfo.outstandingBalance)}</b></td>
                <td>{toCurrency(topTotals.futureTotal + mortgageInfo.houseValue)}</td>
              </tr>
            </tfoot>
          </table>
          <div style={{ display: 'inline-block', margin: 10, verticalAlign: 'top' }}>
            <h6><b>Unrealized Gains</b></h6>
            <table>
              <thead>
                <tr>
                  <th style={{ paddingRight: 20 }}>Tax Free</th>
                  <th style={{ paddingRight: 20 }}>Taxed (Long)</th>
                  <th style={{ paddingRight: 20 }}>Taxed (Short)</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ paddingRight: 20 }}>{toCurrency(topTotals.taxFreeGains)}</td>
                  <td style={{ paddingRight: 20 }}>{toCurrency(topTotals.longTermGains)}</td>
                  <td style={{ paddingRight: 20 }}>{toCurrency(topTotals.shortTermGains)}</td>
                  <td style={{ paddingRight: 20 }}>{toCurrency(topTotals.totalGains)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ display: 'inline-block', margin: 10, verticalAlign: 'top' }}>
            <div>
              <label for="futureSymbol" style={{ margin: 5 }}>Future Symbol</label>
              <input
                type="text"
                id="futureSymbol"
                name="futureSymbol"
                value={futureSymbol}
                onChange={(e) => setFutureSymbol(e.target.value)} // Update state on change
              />
            </div>
            <div>
              <label for="futurePrice" style={{ margin: 5 }}>Future Price</label>
              <input
                type="text"
                id="futurePrice"
                name="futurePrice"
                value={futurePrice}
                onChange={(e) => setFuturePrice(e.target.value)} // Update state on change
              />
            </div>
            <div style={{ marginTop: 10, alignSelf: 'flex-end' }}>
              <button onClick={handleFutureUpdate}>Update</button>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
      <div>
        {data?.map(taxGroup => {
            return (
              <div>
                <h4>{taxGroup.name}</h4>
                <Table
                  columns={bySymbolColumns}
                  data={taxGroup.symbolGroups.filter(symbolGroup => symbolGroup.costBasis < -0.01)}
                  nestedColumns={transactionColumns}
                  getNestedData={getNestedData}>
                </Table>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default FinancesSummary;